@forward 'vars';
@use '../../../core/src/styles/vars' as psv;
@use 'vars' as markers;

.psv-container {
    --psv-markers-plugin-loaded: true;
}

.psv-markers {
    pointer-events: none;
    position: absolute;
    z-index: psv.$hud-zindex;
    width: 100%;
    height: 100%;

    &-svg-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: psv.$polygon-marker-zindex;
    }

    &-css3d-container {
        position: absolute;
        z-index: psv.$polygon-marker-zindex + 1;
    }
}

.psv-marker {
    display: none;
    pointer-events: auto;

    &--normal {
        position: absolute;
        top: 0;
        left: 0;
        z-index: psv.$marker-zindex;
        overflow: visible;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &--css3d {
        display: block;
    }

    &--transparent {
        display: block;
        opacity: 0;
    }

    &--visible {
        display: block;
    }

    &--has-tooltip,
    &--has-content {
        cursor: pointer;
    }
}
